@use '../util' as *;
@use '../globals/colors.scss' as *;

.promo-block{
    &__grid{
        display: grid;
        gap: 50px;
        grid-template-columns: 1fr 2fr;
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }
    h3{
        font-weight: 900;
        color: #213054;
        margin-bottom: 0;
    }
    h4{
        color: #E75723;
        font-weight: 700;
    }
    p{
        font-weight: bold
    };
    &__image, &__descr{
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint-down(small){
            justify-content: center;
            text-align: center;
        }
    }
    .new-label{
        background-color: red;
        font-size: 2rem;
        color: white;
        font-weight: normal;
        padding: 3px 4px;
        margin-left: 5px;
    }
}
.promo-block__new{
    background-color: #f7f7f7;
    padding-top: 80px;
    margin-top: -40px;
    z-index: 1;
    h3{
        display: flex;
        align-items: center;
        @include breakpoint-down(small){
           flex-direction: column;
        }
    }
}