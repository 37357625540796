@use '../util' as *;
@use '../globals/colors.scss' as *;
.timeline-block.serv{
    background: url('/dist/images/bg_big.jpg') no-repeat;
    background-size: cover;
}
.timeline-twoside{
    z-index: 0;
    &__grid{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 0 20px;
        @include breakpoint-down(small){
            grid-template-columns: 2fr 3fr;
        }
        @include breakpoint-down(small){
            grid-template-columns: 2fr 3fr;
        }
    }
    &__image{
        border-right: 1px solid red;
        padding: 40px;
        &:hover{
            + .timeline-twoside__item p{
                transform-origin: left bottom;
	            transform: scale(1.05);
            }
        }
        @include breakpoint-down(small){
            padding: 10px;
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        &::before{
            content: ' ';
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 4px solid red;
            background: red;
            left: -30px;
            width: 20px;
            height: 20px;
            z-index: 400;
            top: 46%;
        }
        &::after{
            content: ' ';
            background: transparent;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 4px solid #192124;
            left: -30px;
            width: 20px;
            height: 20px;
            z-index: 400;
            top: 46%;
        }
        .title{
            text-transform: uppercase;
            color: $yellow;
            font-weight: 600;
            font-size: 2rem;
        }
        p{
            transition: transform .2s;
        }
    }
    .timeline-first{
        min-height: 100px;
        text-align: right;
        padding-right: 84px;
        @include breakpoint-down(medium){
            padding-right: 50px;
        }
        @include breakpoint-down(small){
            padding-right: 40px;
        }
    }
    .timeline-first-image{
        position: relative;
        img{
            left: -47px;
            position: absolute;
            top: -24px;
            max-width: 70px;
        }
    }
    .timeline-end{
        min-height: 100px;
        display: flex;
        align-items: center;
    }
    .timeline-end-image{
        position: relative;
        img{
            left: -94px;
            max-width: 140px;
            position: absolute;
            top: 40px;
            @include breakpoint-down(medium){
                max-width: 100px;
                left: -10px;
            } 
            @include breakpoint-down(small){
                max-width: 60px;
                
            }
        }
    }
    
}