@use './colors' as *;
@use '../util' as *;

html {
	font-size: 14px;
	box-sizing: border-box;
	@include breakpoint-down(large){
		font-size: 11px;
	}
	@include breakpoint-down(medium){
		font-size: 10px;
	}

	@include breakpoint-down(small){
		font-size: 9px;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}
a{
	color: #1054A0;
}
body {
	margin: 0;
	padding: 0;
}
.img-fluid{
	max-width: 100%;
	height: auto;
}
.d-none{
	display: none;
}
.yellow-link{
	color: $yellow;
	&:hover{
		color: white;
	}
}
.orange-link{
	color: $orange;
	&:hover{
		color: white;
	}
}
.white-link{
	color: white;
	&:hover{
		color: $yellow;
	}
}
.underline{
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}
.blue-link{
	color: #1054A0;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}
.btn{
	border-radius: 5px;
	padding: 18px 30px;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	text-align: center;
    vertical-align: middle;
	display: inline-block;
	border: none;
	&:hover{	
		cursor: pointer;
	}
}
.btn-yellow{
	background: rgb(242,211,109);
	background: linear-gradient(180deg, rgba(242,211,109,1) 0%, rgba(244,205,80,1) 50%, rgba(247,198,51,1) 100%);
	color: black;
	font-size: 1.8rem;
    text-transform: uppercase;
	&:hover{
		background: rgb(247,198,51);
		background: linear-gradient(180deg, rgba(247,198,51,1) 0%, rgba(244,205,80,1) 50%, rgba(242,211,109,1) 100%);
	}
}
@keyframes slideAndBounce {
	0% {
	  left: var(--start-left);
	}
	50% {
	  left: calc(90% - 20px);
	}
	100% {
	  left:  var(--start-left);
	}
  }
.btn-red, .btn-red__small{
	background-color: #F81808;
	color: white;
	font-weight: 600;
	text-transform: uppercase;
	width: auto;
	position: relative;
	&__text{
		z-index: 2;
		position: relative;
	}
	&:hover{
		-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
		-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
		box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
		.first-line, .second-line{
				animation-play-state: paused;
			}
	}
	.first-line, .second-line{
		--start-left: 20px;
  		left: var(--start-left);
		transform: skew(-20deg);
		display: block;
		position: absolute;
		background: #F74D36;
		width: 20px;
		height: 99%;
		top: 0;
		z-index: 1;
		animation: slideAndBounce 15s ease-in-out infinite;
		transition: left 1s ease, width 1s ease;
	}
	.second-line{
		--start-left: 50px;
  		left: var(--start-left);
		width: 10px;
		animation: slideAndBounce 11s ease-in-out infinite;
	}
	
}
.btn-red__small{
	padding: 10px 30px;
	@include breakpoint-down(small){
		padding: 5px 20px;
	}
}
.list-inline-unstyled{
	padding: 0;
	li{
		list-style-type: none;
		display: inline;
		padding: 5px;
	}
}
.list-unstyled{
	padding: 0;
	li{
		list-style-type: none;
	}
}
.mb-4{
	margin-bottom: 1.8rem;
}
.mt-1{
	margin-top: .3rem;
}
.mt-2{
	margin-top: 1.2rem;
}
.mt-3{
	margin-top: 2rem;
}
.mt-4{
	margin-top: 3rem;
}
.ps-1{
	padding-left: .5rem;
}
.center-block{
	display: flex;
    align-items: center;
    justify-content: center;
}
.stroke{
	display: inline-block; 
    max-width: fit-content;
	position: relative;
	&:after{
		border-bottom: 2px solid red;
		position: absolute;
		content: " ";
		width: 110%;
		height: 100%;
		transform: rotate(7deg);
		/* transform: rotate(0deg); */
		bottom: 5px;
		left: 0;
	}
}
.text-center{
	text-align: center;
}
.grey{
	color: #727271;
}

.about{
	&__grid{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 10px;	
	}
	&__image { grid-area: 1 / 1 / 2 / 2; text-align: center; }
	&__text { grid-area: 1 / 2 / 2 / 6; }
	&__text-half { 
		grid-area: 2 / 1 / 3 / 4; 
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include breakpoint-down(medium){
			grid-area: 2/1/3/6;
		}
	}
	&__video { 
		grid-area: 2 / 4 / 3 / 6; 
		position: relative;
		@include breakpoint-down(medium){
			grid-area: 3/1/4/6;
			text-align: center;
		}
	}
	&__start-block{
		width: 50%;
		height: 50%;
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		align-items: center;
		display: flex;
		color: white;
	}
	&__text-full { 
		grid-area: 3 / 1 / 4 / 6; 
		@include breakpoint-down(medium){
			grid-area: 4/1/5/6;
		}
	}	

	&__grid-video{
		display: grid;
		grid-template-columns: repeat(5,1fr);
		gap: 20px;
		@include breakpoint-down(small){
			grid-template-columns: 1fr;
			div{
				text-align: center;
			}
		}
	}
	p{
		font-size: 1.45rem;
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
		text-align: justify;
	}
}

.grecaptcha-badge {visibility: hidden;}