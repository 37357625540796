@use '../../util' as *;
@use '../../globals/colors.scss' as *;
.product-card{
    @include breakpoint-down(small){
        margin: 0 auto;
    }
    &__title{
        font-weight: bold;
        margin: 10px 0;
      
        a{
            text-decoration: none;
            font-size: 1.3rem;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &__img, &__image{
        border: 2px solid #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        a{
            padding: 20px;
        }
    }
    img{
        max-width: 100%;
    }
}
.product__attribute{
    ul{
        width: fit-content;
        li{
            padding: 1px 0;
            div{
                float: right;
                padding-left: 10px;
            }
        }
    }
}
.attribute{
    max-width: 400px;
    &__grid{
        display: grid;
        grid-template-columns: 2fr minmax(30px, 1fr);
        gap: 5px 10px;
    }
    &__name{
        display: flex;
        align-items: center;
    }
    &__value{
        display: flex;
        align-items: center;
    }
}