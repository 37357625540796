@use '../util' as *;
@use 'globals' as *;
.menu-wrap {
    display: flex;
    justify-content: space-between;
 }
 .menu-icon {
    font-size: 1.4em;
    color: white;
    line-height: 50px;
 }
nav {
    position: absolute;
    top:65px;
    left:0;
    width: 100%; 
    z-index: 11;
    @include breakpoint-down(small){
      background-color: #213054;
     
    }
 }
 nav ul {
    list-style-type: none;
    li{
      @include breakpoint-down(small){
         text-align: right;
      }
   
    }
 }
 nav ul li a {
    display: inline-block;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 15px;
    @include breakpoint-down(small){
      font-size: 1.5rem;
      line-height: 3rem;
    }
 }
 nav ul li a:hover, nav ul li a:focus, nav ul li a.active {
    color: $yellow;
 }
 nav ul{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
 }
 #checkbox {
    display: none;
 }
#checkbox:not(:checked) ~ nav {
   @include breakpoint-down(small){
      max-height: 0px;
      overflow: hidden;
   }

 }
 #checkbox:checked ~ nav ul {
    max-height: 200px;
    transition: all 0.5s;
 }
.menu{
    padding: 0;
    @include breakpoint-down(small){
      padding: 10px;
   }
}
@include breakpoint(medium) {
   //Для главного экрана
   .menu-icon {
      display: none;
   }

   nav {
      position: relative;
      top: -10px;
      background-color: transparent;
   }

   nav ul {
      max-height: 70px;
      text-align: left;
   }

   nav ul li {
      display: inline-flex;
      margin: 5px;
   }
   //Все остальное
   .header {
      nav {
         top: unset;
         left: unset;
         ul{
            text-align: center;
         }
      }
   }
}