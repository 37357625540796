@use '../util' as *;
@use '../globals/colors.scss' as *;

.breadcrumb{
    padding: 20px 77px;
    background-color: #f7f7f7;
    @include breakpoint-down(small){
        padding: 20px 10px;
    }
    &__grid{
        display: grid;
        grid-template-columns: minmax(100px, 1fr) 2fr;
        gap: 20px;
        &.-full{
          grid-template-columns: 1fr
        }
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }

    a{
        color: black;
        &:hover{
            text-decoration: underline;
        }
    }
    a.yellow{
        color: $yellow;
    }
    .breadcrumb__grid.product{
      grid-template-columns: 1fr;
    }
}
.category-desc{
    &__grid{
        display: grid;
        grid-template-columns: 1fr 6fr;
        gap: 30px;
    }
    img{
        max-height: 100px;
        display: block;
        margin: 0 auto;
    }
    .spoiler{
        input[type=checkbox]{
            display: none;
        }
    }

    #spoiler {
        &:checked {
          + .spoiler__text {
            max-height: 100%;
          }
          ~ label .arrow__down {
            display: none;
          }
          ~ label .arrow__up {
            display: inline;
          }
        }
        &:not(:checked) {
          ~ label .arrow__down {
            display: inline;
          }
          ~ label .arrow__up {
            display: none;
          }
        }
      }

}
