@use '../util' as *;
@use '../globals/colors.scss' as *;
.category-child{
    &__grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 100px;
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }

}