@use '../util' as *;
@use '../globals/colors.scss' as *;

.product-home{
    &__grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }
    .product-card{
        display: flex;
        gap: 50px;
        justify-content: center;
        @include breakpoint-down(small){
            gap: 20px;
        }
        &__img{
            @include breakpoint-down(small){ 
                max-width: 50%;
            }
           
            img{
              width: 100%;  
            }  
        }
        &__descr{
            text-align: left;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
        }
    }
}

.product-title{
    font-weight: 700;
    color: #1054A0;
}