@use '../util' as *;
@use '../globals/colors.scss' as *;
.promo-form{
    background: url('/dist/images/promo_bg.jpg') no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding-bottom: 50px;
    &__grid{
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 50px;
        @include breakpoint-down(medium){
            grid-template-columns: 3fr 1fr;
        }
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }
    color: white;
    background-color: black;
    .form-block{
        max-width: 600px;

        font-size: 1.5rem;
        margin-top: 75px;
        @include breakpoint-down(large){
            max-width: 500px;
        }
        @include breakpoint-down(medium){
            max-width: 450px;
        }
        @include breakpoint-down(small){
            max-width: 300px;
        }
    }
    &__download{
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        @include breakpoint-down(small){
            justify-content: start;
        }
    }
}
.form-block{
    input{
        border-width: 0 0 1px 0;
        border-color: white;
        background-color: transparent;
        color: white;
        width: 100%;
        padding: 7px;
        &::placeholder{
            color: white;
            font-weight: 600;
        }
        &:focus, &:focus-within, &:focus-visible{
            border-width: 0 0 1px 0;
            border-color: white;
        }
    }
    label{
        border-bottom: 1px solid white;
        font-weight: 600;
        &:hover{
            cursor: pointer;
        }
    }
    &__grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }
    &__container{
        display: flex;
        align-items: center;
    }
    .politica{
        color: white;
        font-size: 1rem;
        @include breakpoint-down(small){
            text-align: center;
        }
    }
    textarea{
        width: 100%;
        border-width: 0 0 1px 0;
        border-color: white;
        background-color: transparent;
        color: white;
        padding: 7px;
        &::placeholder{
            color: white;
            font-weight: 600;
        }
        &:focus, &:focus-within, &:focus-visible{
            border-width: 0 0 1px 0;
            border-color: white;
        }
    }
}
.btn-circle__play.btn-circle__play_red{
    background: #F82715;
    &:hover{
        background: #fa4736;
    }
}

.errors-container{
    font-size: 1rem;
    text-align: center;
    padding-top: 10px;
}
.just-validate-success-field {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    border-color: var(--green);
}
.just-validate-error-field {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23B81111'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23B81111' stroke='none'/%3E%3C/svg%3E");
    border-color: var(--error-color);
}
.just-validate-error-field, .just-validate-success-field {
    background-position: right calc(0.375em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.cbf-errors-container-submit.errors-container{
    color: white;
}
.errors-container.big{
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    padding: 50px 0;
    color: #f6c93ee8;
}