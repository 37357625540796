@use '../util' as *;
@use '../globals/colors.scss' as *;
.timeline-block{
    background: url('/dist/images/timeline-banner.png') no-repeat;
    background-size: cover;
    color: white;
    z-index: 0;
    position: relative;

    .container{
        padding: 230px 0 130px 0;
        @include breakpoint-down(medium){
            padding: 50px 0;
        }
    }
    h3{
        @include breakpoint-down(small){
            font-size: 2.5rem;
        }
    }
    h4{
        span{
            background-color: red;
            padding: 3px;
        }

    }
    .sell-text{
        font-size: 1.5rem;
        flex-direction: column;
        font-weight: bold;
        span{
            display: block;
            font-size: 1.6rem;
            margin-bottom: .8rem;
        }
    }
    .btn-red{
        font-size: 1.5rem;
    }
}
ul.timeline{
    max-width: 75%;
    list-style-type: none;
    position: relative;
    &::before{
        content: ' ';
        background: rgb(246,190,22);
        background: linear-gradient(180deg, rgba(246,190,22,0) 0%, rgba(246,190,22,1) 50%, rgba(246,190,22,0) 100%);
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 1px;
        height: 100%;
        z-index: 400;
    }
    li{
        margin: 20px 0;
        padding-left: 20px;
        position: relative;
        &::before{
            content: ' ';
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid rgba(246,190,22,1);
            background: #f6be16;
            left: -20px;
            width: 20px;
            height: 20px;
            z-index: 400;
            top: 0;
        }
        &::after{
            content: ' ';
            background: transparent;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 4px solid #192124;
            left: -18px;
            top: 2px;
            width: 16px;
            height: 16px;
            z-index: 400;
        }
        .title{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.5rem;
        }
        .descr{
            margin-top: -5px;
        }
    }
}