.pagination{
    text-align: center;
    ul{
      display: flex;
      justify-content: center;
      a{
        background-color: #f81808;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        height: 1.7rem;
        width: 1.7rem;
        line-height: 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            font-weight: 700;
        }
      }
    }
    
}