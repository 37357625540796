@use '../util' as *;
@use '../globals/colors.scss' as *;

.just-text{
    &__grid{
        display: grid;
        grid-template-columns: 1fr 11fr;
    }

    h4{
        margin-top: -10px;
        margin-bottom: 10px;
    }
    &__image{
        text-align: right;
        padding-right: 20px;
    }
}