@use '../util' as *;
@use '../globals/colors.scss' as *;

.header{
    background: url('/dist/images/header_bg.jpg') no-repeat;
    background-size: cover;
    color: white;
    &__grid{
        display: grid;
        grid-template-columns: minmax(140px, 2fr) 9fr 2fr;
        padding: 10px 0;
        gap: 20px;
        @include breakpoint-down(small){
            grid-template-columns: 4fr 4fr 1fr;
        }
        @include breakpoint-down(xsmall){
            grid-template-columns: repeat(12, 1fr);
        }
    }
    
    &__phone {
        .header_phone-link {
            font-size: 1.2rem;
            font-weight: bold;
            color: white;
        }

        svg {
            height: 34px;
            stroke-width: 2.24;
            stroke-miterlimit: 22.9256;
            fill: #F6BE16;
            float: left;
        }

        @include breakpoint-down(small) {
            grid-column: 2/3;
            justify-content: right;
        }
        @include breakpoint-down(xsmall){
            justify-content: center;
            grid-area: 1/3/1/12;
        }
    }

    &__adress-content,
    &__phone-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            padding-left: 10px;
        }
    }
    &__logo {
        display: flex;
        align-items: center;

        img {
            float: left;
            height: 70px;
            @include breakpoint-down(xsmall){
                height: 45px;
            }
        }

        span {
            div {
                justify-content: center;
                padding-left: 16px;
            }
            div:last-child{
                font-size: .7rem;
                @include breakpoint-down(small){
                    font-size: .9rem;
                }
            }
        }

        .company-name {
            font-size: 1.8rem;
            font-weight: 700;
            @include breakpoint-down(medium){
                font-size: 1.8rem;
            }
        }

        @include breakpoint-down(small) {
            grid-column: 1/2;
        }
        @include breakpoint-down(xsmall){
            grid-area: 1/1/1/3;
            span {
                display: none;
            }
        }
    }
    &__menu {
      
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include breakpoint-down(small) {
            grid-area: 1 / 3 / 1 / 4;
            margin-top: 0;

            .menu-wrap {
                justify-content: flex-end;
                align-items: center;
            }
        }
        @include breakpoint-down(xsmall){
            grid-area: 1/12/1/13;
        }

    }
    &__phone{
        display: flex;
        align-items: center;
    }
}