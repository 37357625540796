@use '../util' as *;
@use '../globals/colors.scss' as *;

.product-tabs{
    &__list{
        li{
            padding: 10px;
            margin-right: 10px;
            border: 1px solid #F3F3F3;
            white-space: nowrap;
            a{
                color: black;
                font-weight: 500;
            }
            &:hover{
                background-color: $yellow;
                cursor: pointer;

            }
            line-height: 5rem;
        }
        .tabs__btn_active{
            background-color: $yellow;
        }
        @include breakpoint-down(small){
            overflow-x: scroll;
            white-space: nowrap;
        }
    }
}
.tabs__pane {
    display: none !important;
  }

.tabs__pane_show {
    display: grid !important;
}