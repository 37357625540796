@use '../util' as *;
@use '../globals/colors.scss' as *;

.contact{
    &__grid{
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 20px;
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        };
    }
    &__info{
        width: fit-content;
    }
    &__callback{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 600;
        text-align: center;
        .politica{
            font-size: .7rem;
            margin-top: 10px;
        }
    }
}
.info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include breakpoint-down(small){
        grid-template-columns: 1fr;
    }
}
.download-kp{ 
    text-decoration: underline;
    color: black;
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-weight: 500;
    i{
        font-size: 2rem;
        display: block;
        float: left;
        color: red;
        padding-right: 5px;
    }
    a{
        color: black;
    }
    &:hover{
      text-decoration: none; 
    }

}