@use '../util' as *;
@use '../globals/colors.scss' as *;

.product{
  max-width: 100%;
    &__grid{
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        @include breakpoint-down(medium){
          grid-template-columns: 1fr 1fr;
        }
        @include breakpoint-down(small){
          grid-template-columns: 1fr;
        }
    }
    &__image{
      max-width: 100%;
      display: grid;
      overflow: hidden;
    }
    &__buy{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    hr{
        height: 1px;
        width: 100%;
        background-color: #D0D2D3;
        grid-area: 2/1/3/4;
        border: none;
        @include breakpoint-down(small){
          grid-area: 3/1/4/2;
        }
    }
}
.more{
    grid-area: 3/1/4/4;
    display: grid;
    @include breakpoint-down(small){
      grid-area: 4/1/5/2;
    }
    img{
        max-width: 100%;
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 50px;
        @include breakpoint-down(medium){
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-down(small){
            grid-template-columns: 1fr;
        }
    }

}

.gallery-container {
  position: relative;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  overflow: hidden;
  max-height: 350px;
  @include breakpoint-down(small){
    max-width: 100%;
    grid-template-columns: 1fr;
  }
  .swiper-pagination-bullet{
    background-color: #eec132;
  }
}

.gallery-main {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-self: center;
  .swiper-slide{
    width: 100%;
    img{
      display: block;
      width: 100%;
      margin: 0 auto;
      @include breakpoint-down(small){
        max-height: 500px;
        width: auto;
      }
    }
  }
  .swiper-pagination{
    display: none;
    @include breakpoint-down(small){
      display: block;
    }
  }
  .swiper-button {
    &-prev,
    &-next {
      position: absolute;
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
      height: 75px;
      width: 40px;
      color: #e9e9e9;
      background: #1e1e1e;

      &::after {
        font-size: 12px;
      }
    }

    &-prev {
      right: 40px;
      border-right: 1px solid #e9e9e9;
    }
  }

}


.gallery-thumbs {
  order: -1;
  height: 300px;
  @include breakpoint-down(small){
    display: none;
  }

  .swiper-slide {
    img {
      transition: 0.3s;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }

    &-active {
      opacity: 0.9;
    }
  }
  .swiper-slide-thumb-active {
    border: 1px solid $yellow;
  }
}
