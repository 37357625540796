@use '../../util' as *;
@use '../../globals/colors.scss' as *;

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    .form-block{
        width: 70%;
        @include breakpoint-down(small){
            width: 100%;
        }
    }
    .form-block__grid{
        grid-template-columns: 1fr;
    }
    .grid-2fr{
        grid-template-columns: minmax(100px, 200px) 1fr
    }
}


.modal-content {
    position: relative;
    background-color: $modal-bg;
    margin: auto;
    padding: 0;
    width: 45%;
    min-width: 320px;
    max-width: 740px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    padding-bottom: 40px;
    padding-top: 10px;
    @include breakpoint-down(medium){
        width: 70%;
    }
    @include breakpoint-down(small){
        width: 90%;
    }
}


@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}


.close {
    color: white;
    float: right;
    font-size: 48px;
    font-weight: normal;
    margin-top: -15px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header, .modal-header-video {
    padding: 10px 30px;
    background-color: $modal-bg;
    color: white;
}

.modal-body { padding: 10px 30px;}

.modal-footer {
    padding: 10px 30px;
    background-color: $modal-bg;
    color: white;
}

.modal.white{
    .modal-header{
        background-color: white;
    }
    .modal-content {
        background-color: white;
    }
    .close{
        color: black;
    }
    h4{
        color: $blue;
    }
    .tel-orange{
        font-size: 3rem;
        font-weight: 700;
        color: #eb5609;

    }
    .whatsapp{
        color: #009846;
        font-weight: 700;
        font-size: 1.5rem;
    }
}
.modal.modal-video{
    .modal-header-video{
        background-color: transparent;
    }
    .modal-content{
        background-color: rgba(0,0,0,0.4);
    }
}