@use '../util' as *;
@use '../globals/colors.scss' as *;

.footer{
    background-color: #434242;
    padding: 55px 77px;
    color: white;
    @include breakpoint-down(large){
        font-size: 1.29rem;
    }
    @include breakpoint-down(medium){
        font-size: 1.2rem;
    }
    @include breakpoint-down(small){
        padding: 10px;
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        @include breakpoint-down(medium){
            grid-template-columns: repeat(2, 1fr);
            gap:20px;
        }
    }
    &__logo{
        text-align: left;
        span:first-child{
            font-size: 2rem;
        }
        img{ 
            float: left;
            height: 48px;
            margin-right: 10px;
        }
    }
    .first-screen__adress{
        svg{
            height: 1.2rem;
            margin-top: 3px;
        }
    }
    .first-screen__phone{
        svg{
            height: 1.2rem;
            margin-top: 3px;
        }
    }
    .first-screen__adress-content, .first-screen__phone-content{
        align-items: flex-start;
    }
    &__contact{
        display: flex;
        justify-self: start;
        flex-direction: column;
        @include breakpoint-down(small){
            grid-row: 1/2;
            grid-column: 1/4;

        }
    }
    &__menu, &__product-menu{
        display: flex;
        justify-self: center;
        li{
            padding: 4px 0;
        }
        a{
            color: white;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &__callback{
        display: flex;
        justify-self: center;
        flex-direction: column;
        @include breakpoint-down(medium){
            grid-column: 2/3;
            grid-row: 1/1; 
        }
        @include breakpoint-down(small){
            grid-column: 1/4;
            grid-row: 2/3
        }
    }
    &__promo-text{
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
    }
    .politica{
        color: white;
        text-align: center;
        margin-top: 6px;
    }
}