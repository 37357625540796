@use '../util' as *;
@use '../globals/colors.scss' as *;
.catalog{
    img{
        max-width: 100%;
    }
    &__grid{
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;
    }
}
.item{
    &__grid{
        display: grid;
        grid-template-columns: minmax(80px, 1fr) 3fr;
        gap: 20px;
        @include breakpoint-down(small){
            h3{
                font-size: 2.2rem;
            }
        }
    }
    &__content{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }
}