@use '../util' as *;
@use '../globals/colors.scss' as *;

.first-screen {
    color: white;
    background-image: url('/dist/images/slide_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 75px;

    &__grid {
        display: grid;
        grid-gap: .4rem 1rem;
        // grid-template-columns: 
        //     minmax(220px, 8fr)
        //     minmax(0px, 65px) 
        //     minmax(175px, 2fr) 
        //     minmax(0px, 65px) 
        //     minmax(110px, 2fr);
        grid-template-columns: 4fr 1fr 1fr;
        // white-space:nowrap;
        align-items: center;

        @include breakpoint-down(small) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
    }

    &__logo {
        display: flex;
        align-items: center;

        img {
            float: left;
            height: 70px;
        }

        span {
            div {
                justify-content: center;
                padding-left: 16px;
                font-weight: 500;
            }
        }

        .company-name {
            font-size: 2rem;
            font-weight: 700;
        }

        @include breakpoint-down(small) {
            grid-area: 1 / 1 / 2 / 2;
        }
    }

    &__adress {
        svg {
            height: 34px;
            stroke: #F6BE16;
            stroke-width: 2.24;
            stroke-miterlimit: 22.9256;
            fill: none;
        }

        .first-screen__city {
            font-size: 1.2rem;
            font-weight: bold;
        }

        @include breakpoint-down(small) {
            grid-area: 2 / 1 / 3 / 2;
        }
    }

    &__phone {
        .first-screen_phone-link {
            font-size: 1.2rem;
            font-weight: bold;
            color: white;
        }

        svg {
            height: 34px;
            stroke-width: 2.24;
            stroke-miterlimit: 22.9256;
            fill: #F6BE16;
            float: left;
        }

        @include breakpoint-down(small) {
            grid-area: 2 / 2 / 3 / 3;
            justify-content: center;
        }
    }

    &__adress-content,
    &__phone-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            padding-left: 10px;
        }
    }

    &__menu {
        margin-top: 30px;
        grid-area: 2 / 1 / 2 / 4;

        @include breakpoint-down(small) {
            grid-area: 1 / 2 / 1 / 2;
            margin-top: 0;

            .menu-wrap {
                justify-content: flex-end;
                align-items: center;
            }
        }

    }

    &__slide-grid {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
    }
    &__slide{
        @include breakpoint-down(small){
            padding-bottom: 30px !important;
        }
    }
    .slide__text {
        grid-row: 1/2;

        p:first-child {
            color: $yellow;
        }

        h2 {
            margin-top: -16px;

            @include breakpoint-down(small) {
                font-size: 4rem;
                margin-top: 0;
            }
        }

        .btn {
            font-size: 1.8rem;
            text-transform: uppercase;
        }
    }
    &__small-text{
        padding-left: 5px;
    }

    @include breakpoint-down(small) {
        &__slide-grid {
            grid-template-columns: 1fr;
        }

    }

    .slide__image-block {
        position: relative;


        @include breakpoint-down(small) {
            img {
                display: none;
            }
        }
    }
    .swiper-button-next, .swiper-button-prev{
        color: white !important;  
    }
    @include breakpoint-down(small){
        .swiper-button-next::after, .swiper-button-prev::after{
            font-size: 33px;
        }
    } 
    .swiper-wrapper {
        height: fit-content;
    }
}


.start-video__grid {
    justify-content: center;
    display: grid;
    grid-template-columns: 100px auto;

    .start-video__text {
        font-weight: bold;
        align-items: center;
        display: flex;
        text-shadow: 1px 1px 2px black;
    }

    .btn-circle__play {
        background: rgb(242, 211, 109);
        background: linear-gradient(180deg, rgba(242, 211, 109, 1) 0%, rgba(244, 205, 80, 1) 50%, rgba(247, 198, 51, 1) 100%);
        color: black;
        border-radius: 75px;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        text-align: center;
        justify-content: center;
        position: relative;
        z-index: 2;

        &:hover {
            cursor: pointer;
            background: rgb(247, 198, 51);
            background: linear-gradient(180deg, rgba(247, 198, 51, 1) 0%, rgba(244, 205, 80, 1) 50%, rgba(242, 211, 109, 1) 100%);
        }

        .btn-circle__play-circle {
            width: 95px;
            height: 95px;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            top: -10px;
            left: -10px;
            transform-origin: center;
            animation: spin 5s linear infinite;
            will-change: transform;
        }

        @keyframes spin {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
.first-screen .mobile-clear-bg{
    @include breakpoint-down(small){
        background-image: unset;
    }
}
