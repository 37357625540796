@use '../util' as *;
section{
	padding: 55px 77px 55px 77px;
	@include breakpoint-down(medium) {
		padding: 20px;
	}
	@include breakpoint-down(small){
		padding: 10px 10px 10px 10px;
    }
}
header{
	padding: 0 77px;
	@include breakpoint-down(medium) {
		padding: 0 20px;
	}
	@include breakpoint-down(small){
		padding: 0 10px;
    }
}
.container {
	padding: 0 rem(24);
	max-width: rem(1920);
	margin: 0 auto;

	@include breakpoint(large) {
		padding: 0;
		transform: translate(0, 0);
	}
}
