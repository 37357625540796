@use '../util' as *;
@use '../globals/colors.scss' as *;
.service{
    background-image: unset;
    background: #ebecec;
    color: $blue;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
    padding-bottom: 100px;
    z-index: 1;
    position: relative;
    nav ul li a{
         color: $blue;
         &:hover{
            color: $orange;
         }
         @include breakpoint-down(small){
            color: white;
         }
    }
    nav ul li a.active{
        color: $orange;
    }
    svg{
        fill: $orange;
    }
    .first-screen_phone-link{
        color: $blue;
        &:hover{
            color: $orange;
        }
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 calc(50% - 1px) 100% calc(50% - 1px); // Определяет размеры треугольника
        border-color: transparent transparent #f0f0f0 transparent;
      }
    .short-line{
        width: 80px;
        height: 4px;
        background-color: $blue;
        border: none;
        margin: unset;
    }
    &__list{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__item{
        display: flex;
        gap: 20px;
        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            p{
                margin: 0;
                font-size: 1.2rem;
            }
            &:first-child{
                align-items: center;
                img{
                    max-width: 30px;
                }
            }
        }
    }
}

.timeline-block.serv{
    margin-top: -170px;
    @include breakpoint-down(small){
        margin-top: -110px;
    }
    .container{
        @include breakpoint-down(small){
            padding: 150px 0;
        }
    }
}

.first-screen.service{
    .slide__text {
        p:first-child {
            color: $blue;
        }
    }
}
