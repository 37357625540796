@use '../util' as *;

h1,
h2,
h3,
h4,
h5 {
	margin-top: 0;
	margin-bottom: 0;
}
h3{
	margin-bottom: 10px;
}

a,a:active {
	text-decoration: none;
}
p{
	font-size: 1.25rem;
	margin-top: .5rem;
	margin-bottom: .5rem;
}
.small{
	font-size: .9rem;
}
h2{
	font-size: 5.6rem;
}
h3{
	font-size: 3.3rem;
}
h4{
	font-size: 1.5rem;
}
h5{
	font-size: 1.2rem;
}